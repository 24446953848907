import clsx from "clsx"
import { FlexBox } from "@components/wrapper"
import { Helmet } from "@helpers/Helmet"
import Image from "next/image"

const NotFoundPage = () => {
  return (
    <>
      <Helmet title="Not Found" />
      <FlexBox
        direction="col"
        className={clsx("w-4/5 h-screen mx-auto")}
        justify="center"
        align="center"
      >
        <div className="flex justify-center w-full lg:flex-row flex-col">
          <div className="mx-auto">
            <Image
              src="https://harisenin-storage.s3.ap-southeast-1.amazonaws.com/public/assets/pages/error-page_asset.png"
              alt="image page notfound"
              width={568}
              height={400}
            />
          </div>

          <FlexBox className="mx-auto text-center lg:w-auto w-4/5" direction="col">
            <Image
              src="https://harisenin-storage.s3.ap-southeast-1.amazonaws.com/public/assets/pages/error-page_status-404.png"
              alt="error 404"
              className="mx-auto mb-7"
              width={306}
              height={166}
            />
            <h2 className="font-semibold lg:text-2xl">
              Halaman yang kamu akses tidak bisa ditemukan.
            </h2>
            <p className="lg:text-base text-sm">
              Tidak bisa akses halaman? bisa jadi ada kesalahan nih. Silahkan periksa URL kamu atau{" "}
              <a href="https://www.harisenin.com" className="text-green font-medium">
                coba lagi
              </a>
              .
            </p>
          </FlexBox>
        </div>
      </FlexBox>
    </>
  )
}

export default NotFoundPage
